import React from 'react';

const data = [
  {
    category: 'Genes',
    v24: 25992,
    v241: 26090,
    v242: 26392,
    v243: 26484,
    v244: 26798,
    TotalIncrease: 806,
    percentIncrease: 3.1,
  },
  {
    category: 'Diseases/traits',
    v24: 39644,
    v241: 39797,
    v242: 39903,
    v243: 39922,
    v244: 39972,
    TotalIncrease: 328,
    percentIncrease: 0.83,
  },
  {
    category: 'Variants',
    v24: 616660,
    v241: 704086,
    v242: 719161,
    v243: 723970,
    v244: 728547,
    TotalIncrease: 111887,
    percentIncrease: 18.14,
  },
  {
    category: 'Chemicals',
    v24: 3530,
    v241: 3981,
    v242: 4032,
    v243: 4042,
    v244: 4044,
    TotalIncrease: 514,
    percentIncrease: 14.56,
  },
  {
    category: 'GDAs',
    v24: 1770610,
    v241: 1793304,
    v242: 1934730,
    v243: 1943710,
    v244: 1957356,
    TotalIncrease: 186746,
    percentIncrease: 10.55,
  },
  {
    category: 'VDAs',
    v24: 1119130,
    v241: 1201698,
    v242: 1254874,
    v243: 1237183,
    v244: 1245570,
    TotalIncrease: 126440,
    percentIncrease: 11.3,
  },
  {
    category: 'Publications',
    v24: 1434206,
    v241: 1479286,
    v242: 1539056,
    v243: 1560237,
    v244: 1572260,
    TotalIncrease: 138054,
    percentIncrease: 9.63,
  },
];

function ReleasesTables() {
  return (
    <>
      <thead className="text-center">
        <tr>
          <th></th>
          <th colSpan="2" className="border-right">
            2023
          </th>
          <th colSpan="3" className="border-right">
            2024
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <thead className="text-center">
        <tr>
          <th></th>
          <th className="border-right">V24</th>
          <th className="border-right">V24.1</th>
          <th className="border-right">V24.2</th>
          <th className="border-right">V24.3</th>
          <th className="border-right">V24.4</th>
          <th>Total increase (24.4 vs 24)</th>
          <th>% increase (24.4 vs 24)</th>
        </tr>
      </thead>
      <tbody className="text-center">
        {data.map((row, index) => (
          <tr key={index}>
            <td className="border-right">
              <strong>{row.category}</strong>
            </td>
            <td className="border-right">{row.v24}</td>
            <td className="border-right">{row.v241}</td>
            <td className="border-right">{row.v242}</td>
            <td className="border-right">{row.v243}</td>
            <td className="border-right">{row.v244}</td>
            <td>{row.TotalIncrease}</td>
            <td>{row.percentIncrease}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

export default ReleasesTables;
