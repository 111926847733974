import React, { Fragment, useState, useEffect } from 'react';
import { useViewChangeTracker } from '../../../Hooks/UseViewChangeTracker';

import HeatmapChart from '../Charts/Heatmap/HeatmapChart';
import LineChart from '../Charts/Line/LineChart';
import NetworkChart from '../Charts/Network/NetworkChart';

function getInitialChartInfo(selectedChart) {
  return {
    type: selectedChart,
    variable: 'score',
    variableName: 'VDA Score',
    variableFullName: 'VDA Score',
  };
}

function checkNamesTypes(names, view) {
  const namesTitles = names.map(filter => {
    return filter.title;
  });

  return namesTitles.every(title => title === view);
}

function SummaryVDAGraphs({ source, keySearch, view, names, selectedChart }) {
  const [chartInfo, setChartInfo] = useState(() => getInitialChartInfo(selectedChart));

  useViewChangeTracker('SummaryVDAGraphs', view, 'graph');

  useEffect(() => {
    setChartInfo(prevChartInfo => ({ ...prevChartInfo, type: selectedChart }));
  }, [selectedChart]);

  const heatmapLineSearchVariables = [
    { ident: 'score', name: 'VDA Score', fullName: 'VDA Score' },
    {
      ident: 'numPMIDsupportingAssociation',
      name: 'N. Publications',
      fullName: 'Number of Publications',
    },
    { ident: 'EI', name: 'Evidence Index', fullName: 'Evidence Index' },
  ];

  const areNamesSameType = checkNamesTypes(names, view);

  const showNoGraphsLogic = !areNamesSameType;

  return (
    <Fragment>
      {chartInfo.type === 'HEATMAP' && (
        <HeatmapChart
          source={source}
          keySearch={keySearch}
          view={view}
          names={names}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
          baseUrl={'api/vda/summary'}
          parent={'VDA'}
          heatmapSearchVariables={[...heatmapLineSearchVariables]}
        />
      )}

      {chartInfo.type === 'LINE' && (
        <LineChart
          source={source}
          keySearch={keySearch}
          view={view}
          names={names}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
          baseUrl={'api/vda/summary'}
          parent={'VDA'}
          lineSearchVariables={[...heatmapLineSearchVariables]}
        />
      )}

      {chartInfo.type === 'NETWORK' && (
        <NetworkChart
          source={source}
          keySearch={keySearch}
          view={view}
          names={names}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
          baseUrl={'api/vda/summary'}
          parent={'VDA'}
          networkSearchVariables={[...heatmapLineSearchVariables]}
        />
      )}
    </Fragment>
  );
}

export default React.memo(SummaryVDAGraphs);
