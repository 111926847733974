import React, { Component } from 'react';

class SwaggerAPI extends Component {
  render() {
    return (
      <iframe
        id="iFrameSwaggerAPI"
        src={`${process.env.REACT_APP_URL_API_API}doc/swagger`}
        style={{ width: '100%', height: '1000px', backgroundColor: 'black' }}
      />
    );
  }
}

export default SwaggerAPI;
