import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader';
import Footer from '../Components/Home/Footer';
import { searchContext, userAppContext } from '../Context/Context';
import LoadingSpinner from '../Components/UI/LoadingSpinner';
import AdvancedSearchUtiles from '../Components/AdvancedSearch/AdvancedSearchUtiles';

export default function AdvancedSearchPage() {
  const { isAuthenticated } = userAppContext();
  const { loading, error } = searchContext();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="error-message">An error occurred: {error.message}</div>;
  }

  return (
    <>
      <CommonHeader />
      <div className="p-4">
        <div className="search-results-container">
          {isAuthenticated ? (
            <AdvancedSearchUtiles />
          ) : (
            <div className="auth-message text-center">
              <h6>This feature is available only for authenticated users</h6>
              <h6>
                See all the plans <a href="/plans">here</a>.
              </h6>
              <img
                src="../../../static/images/advancedSearchimg.png"
                alt="Not authenticated illustration"
                className="mt-4"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  margin: '0 auto',
                  opacity: 0.5,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
