// Using commonJS module export instead of ES6 export for compatibility with serverless
// update serverless function with changes to this file
// serverless/dgn-cognito-preSignup

const blacklist = [
  '126',
  '163',
  '139',
  '189',
  '1623',
  'outlook',
  'hotmail',
  'yahoo',
  'gmail',
  'qq',
  'vip', // handles any vip.*.* domains ex: vip.qq, vip.163..
  'msn',
  'me',
  'icloud',
  'live',
  'foxmail',
  'aol',
  'googlemail',
  'gmx',
  'protonmail',
  'sina',
  'e-mail.edu.pl',
  '153fdd',
  'ceeun',
  'tthy.site',
  'promail.edu.pl',
  'asaud',
  'smallntm.lol',
  '153fdd',
  'fast.edu.pl',
  'omail.edu.pl',
  'moakt.cc',
  'naver',
];

module.exports = blacklist;
