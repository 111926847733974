export const filterOptions = {
  DISEASES: [
    { value: 'diseaseUMLSCUI', label: 'DISEASE CUI' },
    // { value: 'MSH', label: 'MSH' },
    // { value: 'OMIM', label: 'OMIM' },
    // { value: 'NCI', label: 'NCI' },
    // { value: 'HPO', label: 'HPO' },
    // { value: 'MONDO', label: 'MONDO' },
  ],
  VARIANTS: [{ value: 'strID', label: 'VARIANT rs number' }],
  GENES: [
    { value: 'symbol', label: 'GENE SYMBOL' },
    // { value: 'ensemblID', label: 'ENSEMBL ID' },
    // { value: 'uniprotID', label: 'UNIPROT ID' },
    { value: 'geneID', label: 'GENE ID' },
  ],
  CHEMICALS: [{ value: 'chemUMLSCUI', label: 'CHEMICAL CUI' }],
};

export const renderFilterOptions = (searchType, selectedFilter, setSelectedFilter) => {
  const currentFilters = filterOptions[searchType] || [];

  return (
    <select
      name="filter"
      value={selectedFilter}
      onChange={e => setSelectedFilter(e.target.value)}
      className="custom-dropdown"
    >
      {currentFilters.map(filter => (
        <option key={filter.value} value={filter.value}>
          {filter.label}
        </option>
      ))}
    </select>
  );
};

export const getExpectedFormatMessage = (searchType, selectedFilter) => {
  if (searchType === 'DISEASES') {
    return selectedFilter === 'name'
      ? "Name are: Alzheimer's Disease"
      : 'CUI are: C0002736, C1862939, C2677565';
  }
  if (searchType === 'VARIANTS') {
    if (selectedFilter === 'strID') {
      return 'rs numerber are: rs75290158, rs142200609, rs893051';
    } else if (selectedFilter === 'geneSymbol') {
      return 'Gene Symbol are: BRCA1';
    }
  }

  if (searchType === 'GENES') {
    if (selectedFilter === 'symbol') {
      return 'Symbol are: BRCA1, TP53, EGFR';
    } else if (selectedFilter === 'geneID') {
      return 'ID are: 672';
    }
  }
  if (searchType === 'CHEMICALS') {
    if (selectedFilter === 'chemUMLSCUI') {
      return 'CUI are: C0210657, C0008024, C0055278';
    } else if (selectedFilter === 'chemShowName') {
      return 'Name: are: Acetaminophen';
    }
  }
  return '';
};

export const getMsg = (searchType, selectedFilter) => {
  const messages = {
    DISEASES: {
      diseaseUMLSCUI: 'CUI',
      MSH: 'MSH',
    },
    VARIANTS: {
      strID: 'rs number',
    },
    GENES: {
      symbol: 'Symbol',
    },
    CHEMICALS: {
      chemUMLSCUI: 'CUI',
    },
  };

  return messages[searchType]?.[selectedFilter] || '';
};

export const getItemCount = text => text.split('\n').filter(line => line.trim() !== '').length;

export const validateInput = (input, searchType, selectedFilter) => {
  if (searchType === 'DISEASES') {
    if (selectedFilter === 'diseaseUMLSCUI') {
      const cuiRegex = /^[A-Za-z0-9]{7,8}$/;
      return cuiRegex.test(input);
    }
    if (selectedFilter === 'MSH') {
      const cuiRegex = /^[A-Za-z0-9]{7,8}$/;
      return cuiRegex.test(input);
    }
    if (selectedFilter === 'name') {
      const nameRegex = /^[A-Za-z0-9\s\-'.,]+$/;
      return nameRegex.test(input) && !/^C\d+$/.test(input);
    }
    if (selectedFilter === 'OMIM') {
      const omimRegex = /^\d{5,6}$/;
      return omimRegex.test(input);
    }

    if (selectedFilter === 'NCI') {
      const nciRegex = /^C\d{5,6}$/;
      return nciRegex.test(input);
    }

    if (selectedFilter === 'EFO') {
      const efoRegex = /^\d{7}$/;
      return efoRegex.test(input);
    }

    if (selectedFilter === 'DO') {
      const doRegex = /^\d{7}$/;
      return doRegex.test(input);
    }

    if (selectedFilter === 'ORDO') {
      const ordoRegex = /^\d{3}$/;
      return ordoRegex.test(input);
    }

    if (selectedFilter === 'HPO') {
      const hpoRegex = /^HP:\d{7}$/;
      return hpoRegex.test(input);
    }

    if (selectedFilter === 'MONDO') {
      const mondoRegex = /^\d{7}$/;
      return mondoRegex.test(input);
    }
  }
  if (searchType === 'VARIANTS') {
    if (selectedFilter === 'strID') {
      const rsidRegex = /^rs\d{1,10}$/;
      return rsidRegex.test(input);
    }
    if (selectedFilter === 'geneSymbol') {
      const geneSymbolRegex = /^[A-Za-z0-9_-]+$/;
      return geneSymbolRegex.test(input);
    }
  }
  if (searchType === 'GENES') {
    if (selectedFilter === 'symbol') {
      const geneSymbolRegex = /^[A-Za-z0-9_-]+$/;
      return geneSymbolRegex.test(input);
    }
    if (selectedFilter === 'geneID') {
      const geneIDRegex = /^\d+$/;
      return geneIDRegex.test(input);
    }
    if (selectedFilter === 'ensemblID') {
      const ensemblIDRegex = /^ENSG\d{11}$/;
      return ensemblIDRegex.test(input);
    }

    if (selectedFilter === 'uniprotID') {
      const uniprotIDRegex = /^[A-Za-z0-9]{6,10}$/;
      return uniprotIDRegex.test(input);
    }
  }
  if (searchType === 'CHEMICALS') {
    if (selectedFilter === 'chemUMLSCUI') {
      const chemCUIRegex = /^[A-Za-z0-9]{8,10}$/;
      return chemCUIRegex.test(input);
    }
    if (selectedFilter === 'chemShowName') {
      const chemNameRegex = /^[A-Za-z0-9\s\-'.,]+$/;
      return chemNameRegex.test(input) && !/^C\d{7}$/.test(input);
    }
  }
  return false;
};
