import React from 'react';
import { useLocation } from 'react-router-dom';

function Biomarkers() {
  const location = useLocation();
  const { hash, pathname } = location;

  return (
    <section>
      <div className="container pt-4 d-flex">
        <div
          className={`tab-pane ${hash === '#Biomarkers' || (!hash && pathname === '/Tools') ? 'active show' : ''}`}
          id="Biomarkers"
        >
          <h2>Biomarkers</h2>
          <h6 className="p-t4">
            The genomic and proteomic biomarkers in clinical trials described in Piñero et al. Comput Struct
            Biotechnol J. 2023 Mar 16;21:2110-2118. doi: 10.1016/j.csbj.2023.03.014. PMID: 36968019 have been
            integrated into
            <a href="https://disgenet.com/" target="_blank" rel="noopener noreferrer">
              {' '}
              <u> DISGENET</u>
            </a>
            . Here
            <a
              href="https://disgenet.com/search?view=DISEASES&idents=C0028754&source=CLINICALTRIALS&tab=GDA"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              <u>you see an example</u>
            </a>{' '}
            of the biomarkers in clinical trials for Obesity.
          </h6>
          <div style={{ paddingBottom: '320px' }}></div>
        </div>
      </div>
    </section>
  );
}

export default Biomarkers;
