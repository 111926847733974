import React, { useState, useEffect } from 'react';
import { searchContext, userAppContext } from '../../Context/Context';
import { advancedExamples } from './AdvancedExamples';
import AdvancedSearch from './AdvancedSearch';
import SuggestionsTable from './SugestionsTabla';
import useFetchSuggestions from '../../Hooks/useFetchSuggestions';
import { validateInput } from './AdvancedSearchHelpers';

const AdvancedSearchUtiles = () => {
  const { searchItems, setSearchItems } = searchContext();
  const { authorization, isAuthenticated } = userAppContext();
  const [searchType, setSearchType] = useState('DISEASES');
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [text, setText] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showTextarea, setShowTextarea] = useState(true);
  const [foundItems, setFoundItems] = useState([]);
  const [notFoundItems, setNotFoundItems] = useState([]);
  const [showFoundItems, setShowFoundItems] = useState(false);
  const [exceededLimit, setExceededLimit] = useState(false);

  const {
    suggestionsData,
    loading,
    error,
    foundItems: fetchedFoundItems,
    notFoundItems: fetchedNotFoundItems,
    fetchSuggestions,
  } = useFetchSuggestions(searchType, selectedFilter, authorization, isAuthenticated);

  useEffect(() => {
    resetSearch();
  }, [searchType, selectedFilter]);

  const resetSearch = () => {
    //setSuggestionsData([]);
    setSearchItems([]);
    //setError(null);
    setText('');
    setShowSuggestions(false);
    setShowFoundItems(false);
    setShowTextarea(true);
    setSelectedItems([]);
    setNotFoundItems([]);
  };

  const handleQueryChange = async e => {
    setText(e.target.value);
    setShowSuggestions(false);
  };

  const handleSearchClick = async () => {
    if (!text.trim()) {
      alert('Please provide input text');
      return;
    }

    const inputs = text
      .split('\n')
      .map(line => line.trim())
      .filter(line => line);

    if (inputs.length > 100) {
      setExceededLimit(true);
      setShowTextarea(true);
      alert('Search limit of 100 items exceeded.');
      return;
    } else {
      setExceededLimit(false);
    }
    if (inputs.length) {
      await fetchSuggestions(inputs);
      setShowTextarea(false);
      setShowSuggestions(true);
    } else {
      resetSearch();
    }
  };

  const handleBackClick = () => {
    resetSearch();
  };

  const handleInputValidation = input => {
    const isValid = validateInput(input, searchType, selectedFilter);
    return isValid;
  };
  const handleCheckboxSelection = item => {
    setSelectedItems(prevItems => {
      if (prevItems.includes(item)) {
        return prevItems.filter(selected => selected !== item);
      } else {
        return [...prevItems, item];
      }
    });
  };

  const handleSearchSelectedItems = () => {
    if (selectedItems.length === 0) {
      alert('Please select at least one item to search.');
      return;
    }

    setFoundItems([]);
    setShowFoundItems(false);

    const uniqueSelectedItems = selectedItems.filter((item, index, self) => {
      const itemString = JSON.stringify(item);
      return index === self.findIndex(t => JSON.stringify(t) === itemString);
    });

    const itemsString = uniqueSelectedItems
      .map(item => {
        if (!item) return '';
        switch (searchType) {
          case 'DISEASES':
            return item.diseaseUMLSCUI || '';
          case 'VARIANTS':
            return item.strID || '';
          case 'GENES':
            return item.geneID || '';
          case 'CHEMICALS':
            return item.chemUMLSCUI || '';
          default:
            return '';
        }
      })
      .join('-');

    const tab = searchType === 'VARIANTS' ? 'VDA' : 'GDA';

    const url = `/search?view=${searchType}&idents=${itemsString}&source=ALL&tab=${tab}`;
    window.open(url, '_blank');

    setFoundItems(uniqueSelectedItems);
    setShowFoundItems(true);
  };

  const handleClearAll = () => {
    setSelectedItems([]);
    setFoundItems([]);
  };
  const clearInputText = () => {
    setText('');
  };

  const showExamples = () => {
    const exampleItems = advancedExamples[searchType]?.[selectedFilter] || [];
    setText(exampleItems.join('\n'));
  };

  const renderSuggestions = () => {
    return (
      <SuggestionsTable
        suggestionsData={suggestionsData}
        selectedItems={selectedItems}
        handleCheckboxSelection={handleCheckboxSelection}
        searchType={searchType}
        selectedFilter={selectedFilter}
      />
    );
  };
  const handleSelectAll = () => {
    const allResults = suggestionsData.flatMap(item => item.results);
    setSelectedItems(allResults);
  };

  return (
    <AdvancedSearch
      searchType={searchType}
      setSearchType={setSearchType}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      text={text}
      handleQueryChange={handleQueryChange}
      handleSearchClick={handleSearchClick}
      clearInputText={clearInputText}
      showExamples={showExamples}
      renderSuggestions={renderSuggestions}
      loading={loading}
      suggestionsData={suggestionsData}
      foundItems={foundItems}
      notFoundItems={notFoundItems}
      showFoundItems={showFoundItems}
      handleBackClick={handleBackClick}
      handleSearchSelectedItems={handleSearchSelectedItems}
      handleSelectAll={handleSelectAll}
      handleClearAll={handleClearAll}
      showTextarea={showTextarea}
      validateInput={handleInputValidation}
    />
  );
};

export default AdvancedSearchUtiles;
