import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { posts } from '../Blog/Posts';
import PostInfo from '../Blog/PostInfo';

const PostImg = ({ image }) => <img src={image} alt="Post" className="blog-home-card-img" />;

export default function BlogHome() {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);

  const [isTransitioning, setIsTransitioning] = useState(false);
  const postsPerPage = 3;

  const totalPages = Math.ceil(posts.length / postsPerPage);
  const handleDotClick = index => {
    setCurrentStartIndex(index * postsPerPage);
  };
  const visiblePosts = posts.slice(currentStartIndex, currentStartIndex + postsPerPage);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setIsTransitioning(true);

  //     setTimeout(() => {
  //       setCurrentStartIndex(prevIndex => (prevIndex + postsPerPage) % posts.length);
  //       setIsTransitioning(false);
  //     }, 500);
  //   }, 15000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div>
      <h3 className="custom-home-h3 gradient-color">
        Read the latest articles on our{' '}
        <Link className="gradient-color" to="/blog">
          Blog
        </Link>
      </h3>
      <hr></hr>
      <div className="blog-home-container">
        <div className="blog-home-cards">
          {visiblePosts.map(post => (
            <div key={post.postName} className={`blog-home-card ${isTransitioning ? '' : 'visible'}`}>
              <Link to={`/${post.postName}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="post-thumbnail">
                  <PostImg image={post.image} />
                </div>
                <div className="blog-home-card-content">
                  <h3 className="blog-home-card-title gradient-color h3-post">{post.title}</h3>
                  <PostInfo className="post-info" category={post.category} date={post.date} />
                  <p className="blog-home-card-description">{post.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="slider-dots">
          {Array.from({ length: totalPages }).map((_, index) => (
            <div
              key={index}
              className={`slider-dot ${currentStartIndex / postsPerPage === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
