import React, { useEffect } from 'react';
import {
  filterOptions,
  renderFilterOptions,
  getExpectedFormatMessage,
  getMsg,
  getItemCount,
} from './AdvancedSearchHelpers';

const AdvancedSearch = ({
  searchType,
  setSearchType,
  selectedFilter,
  setSelectedFilter,
  text,
  handleQueryChange,
  handleSearchClick,
  clearInputText,
  showExamples,
  renderSuggestions,
  loading,
  suggestionsData,
  handleBackClick,
  handleSearchSelectedItems,
  handleSelectAll,
  handleClearAll,
  showTextarea,
  validateInput,
}) => {
  useEffect(() => {
    if (filterOptions[searchType] && filterOptions[searchType].length > 0) {
      setSelectedFilter(filterOptions[searchType][0].value);
    }
  }, [searchType, setSelectedFilter]);

  const filteredNotFoundItems = suggestionsData.filter(item => {
    return item.validationError || !validateInput(item.query, searchType);
  });

  const uniqueResults = new Set();
  const uniqueQueries = new Set();

  suggestionsData.forEach(item => {
    uniqueQueries.add(item.query);

    if (!item.validationError && item.found && Array.isArray(item.results)) {
      item.results.forEach(result => {
        uniqueResults.add(JSON.stringify(result));
      });
    }
  });

  const totalSearched = uniqueQueries.size;
  const totalFound = uniqueResults.size;

  return (
    <div className="row">
      <div className="multiple-filters">
        <div className="ms-radio-group" onChange={e => setSearchType(e.target.value)}>
          {['DISEASES', 'VARIANTS', 'GENES', 'CHEMICALS'].map(type => (
            <label key={type} className="custom-radio-label">
              <input type="radio" name="searchType" value={type} checked={searchType === type} />
              <span className="custom-radio"></span>
              {type}
            </label>
          ))}
        </div>
        <div className="filter-radio-group">
          {searchType && renderFilterOptions(searchType, selectedFilter, setSelectedFilter)}
        </div>
        <div>
          <div>
            {showTextarea ? (
              <div>
                <div>
                  <label>Add your search list (separate items by line):</label>
                </div>
                <textarea
                  placeholder="Enter (one per line)"
                  value={text}
                  onChange={handleQueryChange}
                  className="filter-textarea"
                  rows={5}
                  style={{ width: '100%' }}
                />
                <button onClick={handleSearchClick} className="filter-upload">
                  Search List
                </button>
                <button onClick={clearInputText} className="filter-upload">
                  Clear List
                </button>
                <button onClick={showExamples} className="filter-upload">
                  Show Examples
                </button>
                <p style={{ margin: '10px' }}>Items: {getItemCount(text)}</p>{' '}
              </div>
            ) : (
              <div>
                {renderSuggestions()}

                <div className="error-message">
                  {!loading && suggestionsData.length === 0 && (
                    <p style={{ color: 'red', fontStyle: 'italic' }}>
                      No data found or the service is temporarily unavailable.
                    </p>
                  )}

                  {filteredNotFoundItems.length > 0 && (
                    <div>
                      <p style={{ color: 'red', fontStyle: 'italic' }}>
                        Input search does not follow the expected format for {searchType}{' '}
                        {getMsg(searchType, selectedFilter)}:
                      </p>
                      <ul style={{ color: 'red', fontStyle: 'italic' }}>
                        {Array.from(
                          new Map(filteredNotFoundItems.map(item => [item.query, item])).values(),
                        ).map((uniqueItem, index) => (
                          <li key={index}>{uniqueItem.query}</li>
                        ))}
                      </ul>
                      Examples of {searchType} {getExpectedFormatMessage(searchType, selectedFilter)}
                    </div>
                  )}
                </div>

                <button onClick={handleBackClick} className="filter-upload">
                  Back
                </button>
                <button onClick={handleSearchSelectedItems} className="filter-upload">
                  Search Selected Items
                </button>
                <button onClick={handleSelectAll} className="filter-upload">
                  Select All
                </button>
                <button onClick={handleClearAll} className="filter-upload">
                  Clear All
                </button>
                <p>
                  From the <strong>{totalSearched}</strong> unique input items, we found{' '}
                  <strong>{totalFound}</strong> in DISGENET.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;
