const filterTypes = {
  string: {
    type: 'string',
    emptyValue: '',
    operators: [
      {
        name: 'eq',
        filterFn: ({ value, filterValue }) => value === filterValue,
      },
      {
        name: 'contains',
        filterFn: ({ value, filterValue }) => value.toLowerCase().includes(filterValue.toLowerCase()),
      },
    ],
  },
  number: {
    type: 'number',
    emptyValue: null,
    operators: [
      {
        name: 'eq',
        filterFn: ({ value, filterValue }) => value === filterValue,
      },
      {
        name: 'gt',
        filterFn: ({ value, filterValue }) => value > filterValue,
      },
      {
        name: 'lt',
        filterFn: ({ value, filterValue }) => value < filterValue,
      },
      {
        name: 'lte',
        filterFn: ({ value, filterValue }) => value <= filterValue,
      },
      {
        name: 'gte',
        filterFn: ({ value, filterValue }) => value >= filterValue,
      },
    ],
  },
  select: {
    type: 'select',
    emptyValue: null,
    operators: [
      {
        name: 'eq',
        filterFn: ({ value, filterValue }) => value === filterValue,
      },
    ],
  },
};

function getSizePage({ totalCount, limit, page }) {
  const pageTotal = Math.ceil(totalCount / limit) - 1;
  const numElements = page === pageTotal ? totalCount % limit : limit;
  return numElements > 10 ? 10 : numElements;
}

function getSizes({ columns, datos }) {
  const sizes = columns.map(column => column.header.props.children.length + 2);

  datos.payload.forEach(fila => {
    columns.forEach((column, index) => {
      if (column.name === 'sentenceHTML') {
        let restaSpan = 0;
        if (fila[column.name]) {
          const spanMatches = fila[column.name].match(/span/g);
          restaSpan = spanMatches ? spanMatches.length * 25 : 0;

          sizes[index] = Math.max(sizes[index], fila[column.name].toString().length - restaSpan);
          if (sizes[index] > 150) sizes[index] = 100;
        }
      } else if (fila[column.name]) {
        sizes[index] = Math.max(sizes[index], fila[column.name].toString().length);
      }
    });
  });

  return sizes;
}

function setSizesColumns({ columns, sizes }) {
  let factor = 9;
  let width = 25;

  if (columns.length < 6) {
    factor = 19;
    width = 45;
  } else if (columns.length < 10) {
    factor = 11;
    width = 45;
  }

  return columns.map((column, index) => {
    if (column.columnSize != null) {
      sizes[index] = column.columnSize;
    } else if (sizes[index] < 1) {
      sizes[index] = column.header.props.children.length + 2;
    }

    column.defaultWidth = sizes[index] * factor + width;
    return column;
  });
}

export { filterTypes, getSizePage, getSizes, setSizesColumns };
