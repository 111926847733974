import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { userAppContext } from '../../Context/Context';

function ApiHashLinks() {
  const location = useLocation();
  const currentHash = location.hash;
  const currentPath = location.pathname;
  const { user } = userAppContext();
  const userRole = user['cognito:groups'][0];
  // console.log(user['cognito:groups']);
  const rolesAllowedDownloadDump = ['ROLE_UNLIMITED', 'ROLE_DEVELOPER'];

  const commonClassNames = 'mb-1 dropdown-item text-wrap';
  const subItemClassNames = 'subitem ml-3';

  const disgenetDownloadContent = (
    <React.Fragment>
      <h5 className="">DISGENET download</h5>
      <HashLink
        to="#download"
        data-toggle="tab"
        tabIndex={0}
        data-target="#download"
        className={`${commonClassNames} ${currentHash === '#download' ? 'active' : ''}`}
      >
        How to download DISGENET
      </HashLink>
    </React.Fragment>
  );

  return (
    <section>
      <div>
        <div className="nav px-4 dropdown-menu-inline dropdown-menu-rounded dropdown-menu-hover-primary">
          <h5 className="mt-3">R package</h5>
          <HashLink
            to="#Rinstallation"
            data-toggle="tab"
            tabIndex={0}
            data-target="#Rinstallation"
            className={`${commonClassNames} ${(currentHash === '' && currentPath === '/Tools') || currentHash === '#Rinstallation' ? 'active' : ''}`}
          >
            Installation
          </HashLink>
          <HashLink
            to="#Rdocumentation"
            data-toggle="tab"
            tabIndex={0}
            data-target="#Rdocumentation"
            className={`${commonClassNames} ${currentHash === '#Rdocumentation' ? 'active' : ''}`}
          >
            Documentation
          </HashLink>
          <h5 className="mt-3">API</h5>
          <HashLink
            to="#console"
            data-toggle="tab"
            tabIndex={0}
            data-target="#console"
            className={`${commonClassNames} ${currentHash === '#console' ? 'active' : ''}`}
          >
            Interactive Console
          </HashLink>
          <HashLink
            to="#howtouse"
            data-toggle="tab"
            tabIndex={0}
            data-target="#howtouse"
            className={`${commonClassNames} ${currentHash === '#howtouse' ? 'active' : ''}`}
          >
            How to use the API
          </HashLink>
          <HashLink
            to="#endpoints"
            data-toggle="tab"
            tabIndex={0}
            data-target="#endpoints"
            className={`${commonClassNames} ${subItemClassNames} ${
              currentHash === '#endpoints' ? 'active' : ''
            }`}
          >
            Endpoints
          </HashLink>
          <HashLink
            to="#examples"
            data-toggle="tab"
            tabIndex={0}
            data-target="#examples"
            className={`${commonClassNames} ${subItemClassNames} ${currentHash === '#examples' ? 'active' : ''}`}
          >
            Code examples
          </HashLink>
          <HashLink
            to="#support"
            data-toggle="tab"
            tabIndex={0}
            data-target="#support"
            className={`${commonClassNames} ${subItemClassNames} ${currentHash === '#support' ? 'active' : ''}`}
          >
            Information and support
          </HashLink>
          <h5 className="mt-3">Cytoscape App</h5>
          <HashLink
            to="#installcyto"
            data-toggle="tab"
            tabIndex={0}
            data-target="#installcyto"
            className={`${commonClassNames} ${currentHash === '#installcyto' ? 'active' : ''}`}
          >
            Installation
          </HashLink>
          {rolesAllowedDownloadDump.includes(userRole) && disgenetDownloadContent}

          <h5 className="mt-3">DISGENET AI</h5>
          <HashLink
            to="#assistant"
            data-toggle="tab"
            tabIndex={0}
            data-target="#assistant"
            className={`${commonClassNames} ${currentHash === '#assistant' ? 'active' : ''}`}
          >
            DISGENET Assistant <i class="bi bi-stars"></i>
          </HashLink>
        </div>
      </div>
    </section>
  );
}

export default ApiHashLinks;
