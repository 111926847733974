import React from 'react';

import { VDATable } from '../../Models/VDAModel';

const baseLink = 'https://www.ncbi.nlm.nih.gov/';
const baseLinkUniprot = 'https://www.uniprot.org/';
const baseDiseaseLink = 'https://www.ncbi.nlm.nih.gov/medgen/';

function getGeneNCBIid(geneNcbiID) {
  const geneLink = `${baseLink}gene/${geneNcbiID}`;
  return (
    <a href={geneLink} target="_blank" rel="noopener noreferrer">
      {geneNcbiID} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}
//
function getDiseaseLink(row) {
  const vdaData = new VDATable(row);
  const diseaseLink = `${baseDiseaseLink}${vdaData.diseaseUMLSCUI}`;
  const disease = vdaData.diseaseUMLSCUI;
  return (
    <a href={diseaseLink} target="_blank" rel="noopener noreferrer">
      {disease} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function getDiseaseName(row) {
  const diseaseLink = `${baseDiseaseLink}${row.data.diseaseUMLSCUI}`;
  return (
    <a href={diseaseLink} target="_blank" rel="noopener noreferrer">
      {row.data.diseaseName} {''}
      <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function getDisease(row) {
  const diseaseLink = `${baseDiseaseLink}${row.data.diseaseUMLSCUI}`;
  return (
    <a href={diseaseLink} target="_blank" rel="noopener noreferrer">
      {row.data.name} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function getPmidLink(pmid) {
  const pmidLink = `${baseLink}pubmed/${pmid}`;
  return (
    <a href={pmidLink} target="_blank" rel="noopener noreferrer">
      {pmid} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function variantLink(variant) {
  const baseUrl = 'https://www.ncbi.nlm.nih.gov/snp/';
  const variantUrl = `${baseUrl}${variant}`;
  return (
    <a href={variantUrl} target="_blank" rel="noopener noreferrer">
      {variant} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function referenceLink(variant) {
  const baseUrl = 'https://pubmed.ncbi.nlm.nih.gov/';
  const variantNumber = variant.replace(/\D/g, '');
  const variantUrl = `${baseUrl}${variantNumber}`;
  return (
    <a href={variantUrl} target="_blank" rel="noopener noreferrer">
      {variant} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function getNctidLink(nctid) {
  const baseUrl = 'https://clinicaltrials.gov/search?id=';
  const nctidUrl = `${baseUrl}${nctid}`;
  return (
    <a href={nctidUrl} target="_blank" rel="noopener noreferrer">
      {nctid} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

export {
  getDisease,
  getPmidLink,
  getGeneNCBIid,
  getDiseaseLink,
  getDiseaseName,
  variantLink,
  getNctidLink,
  referenceLink,
};
