import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="containerFooter">
          <nav className="container navFooter">
            <div className="footerColumnsContainer">
              <div className="footerColumn">
                <div className="footerLinksContainer">
                  <div className="footerLinksColumn">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <a href="https://www.medbioinformatics.com/about" target="_blank" rel="noreferrer">
                          Our Company
                        </a>
                      </li>
                      <li>
                        <Link to="/Support">Support</Link>
                      </li>
                      <li>
                        <Link to="/plans">Our Plans</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footerLinksColumn">
                    <ul>
                      <li>
                      <a 
                        href="https://disgenet.freshdesk.com/support/home"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help Center
                      </a>
                      </li>
                      <li>
                        <Link to="/Privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/Cookies-policy">Cookies Policy</Link>
                      </li>
                      <li>
                        <Link to="/Legal">Legal</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footerColumn">
                <div className="logoSocialMediaContainer">
                  <a
                    href="https://www.linkedin.com/company/medbioinformatics/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="logoSocialMedia"
                      src="../../static/images/linkedin-in.svg"
                      alt="linkedin logo"
                    />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCGazBaBHYyx4-fIW8TZXYbg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="logoSocialMedia pt-1"
                      src="../../static/images/youtube.svg"
                      alt="youtube logo"
                    />
                  </a>
                </div>

                <div className="infoFooter mt-4 mb-4 text-center">
                  Info:{' '}
                  <a href="mailto:info@disgenet.com" title="copy">
                    info@disgenet.com
                  </a>{' '}
                  <br /> ©2024 MedBioinformatics Solutions SL
                </div>
              </div>
            </div>
          </nav>
        </div>
      </footer>
    );
  }
}

export default Footer;
