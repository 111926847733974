import React from 'react';
import Footer from '../Components/Home/Footer';
import CommonHeader from '../Components/Headers/CommonHeader';
import ProfileAreaContent from '../Components/ProfileArea/ProfileAreaContent';
import { userAppContext } from '../Context/Context';
import NotLoginPage from '../Components/ProfileArea/NotLoginPage';

const ProfileArea = () => {
  const { authorization, isAuthenticated } = userAppContext();

  return (
    <React.Fragment>
      <CommonHeader />
      {!isAuthenticated ? <NotLoginPage /> : <ProfileAreaContent />}
      <Footer />
    </React.Fragment>
  );
};

export default ProfileArea;
