import React, { useEffect, useState } from 'react';

function PricingTables() {
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const tableOffset = document.getElementById('scrollableTable').getBoundingClientRect().top;
      setIsHeaderSticky(tableOffset <= 0);
    };

    const syncHeaderWidths = () => {
      const bodyCells = document.querySelectorAll('tbody tr:first-child td');
      const headerCells = document.querySelectorAll('thead th');

      bodyCells.forEach((bodyCell, index) => {
        const width = window.getComputedStyle(bodyCell).width;
        headerCells[index].style.width = width;
      });
    };

    syncHeaderWidths();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', syncHeaderWidths);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', syncHeaderWidths);
    };
  }, []);

  return (
    <div className="table-container">
      <table
        id="scrollableTable"
        className={`table panther-table table-edit ${isHeaderSticky ? 'sticky' : ''}`}
        style={{ margin: '10px auto 60px', textAlign: 'center' }}
      >
        <thead>
          <tr className="table-titles">
            <th></th>
            <th>ACADEMIC</th>
            <th>STANDARD</th>
            <th>ADVANCED</th>
            <th>PREMIUM</th>
          </tr>
        </thead>
        <tr>
          <th className="table-subtitles" colSpan="5">
            Access Features
          </th>
        </tr>
        <tbody>
          <tr>
            <td>
              <strong>Web platform</strong>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Export results</strong>
            </td>

            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Web visualisations</strong>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>REST-API</strong>
            </td>
            <td>
              {' '}
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                {' '}
                <a href="https://gitlab.com/medbio/disgenet2r" target="_blank" rel="noopener noreferrer">
                  <u>R package</u>
                </a>
              </strong>
            </td>
            <td>
              {' '}
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Offline access</strong>
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              <strong>
                {' '}
                <a
                  href="https://apps.cytoscape.org/apps/disgenetapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>Cytoscape App</u>
                </a>
              </strong>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>AI Assistant</strong>
            </td>
            <td>-</td>
            <td><i className="bi bi-check2-circle text-success"></i></td>
            <td><i className="bi bi-check2-circle text-success"></i></td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th className="table-subtitles" colSpan="5">
              Data
            </th>
          </tr>
          <tr>
            <td>
              <strong>Data</strong>
            </td>
            <td>Curated Data</td>
            <td>Full Database</td>
            <td>Full Database</td>
            <td>Full Database</td>
          </tr>
          <tr>
            <td>
              <strong>Data updates</strong>
            </td>
            <td>Quarterly</td>
            <td>Quarterly</td>
            <td>Quarterly</td>
            <td>Quarterly</td>
          </tr>
          <tr>
            <td>
              <strong>Metrics</strong>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>LoF/GoF annotations</strong>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Clinical Trial annotations</strong>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Genes</strong>
            </td>
            <td>Over 13,500</td>
            <td>Over 26,800</td>
            <td>Over 26,800</td>
            <td>Over 26,800</td>
          </tr>
          <tr>
            <td>
              <strong>Diseases</strong>
            </td>
            <td>Over 14,500</td>
            <td>Over 39,900</td>
            <td>Over 39,900</td>
            <td>Over 39,900</td>
          </tr>
          <tr>
            <td>
              <strong>GDAs</strong>
            </td>
            <td>Over 92,900</td>
            <td>Over 1.95M</td>
            <td>Over 1.95M</td>
            <td>Over 1.95M</td>
          </tr>
          <tr>
            <td>
              <strong>VDAs</strong>
            </td>
            <td>Over 1M</td>
            <td>Over 1.2M</td>
            <td>Over 1.2M</td>
            <td>Over 1.2M</td>
          </tr>
          <tr>
            <td>
              <strong>Text-mined insights</strong>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Drugs & chemicals</strong>
            </td>
            <td>-</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Advanced Search</strong>
            </td>
            <td>Available with curated data</td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
            <td>
              <i className="bi bi-check2-circle text-success"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PricingTables;
