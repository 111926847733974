import React, { Fragment, useRef } from 'react';
import PathwayDiagramList from './PathwayDiagramList';

export default function PathwayDiagramContent({
  geneSymbol,
  onChangePathway,
  selectedPathway,
  highlightPathway,
  availablePathways,
}) {
  const pathwaysListRef = useRef();
  const diagramTitleRef = useRef();

  const scrollToPathwaysList = () => {
    pathwaysListRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Fragment>
      <div
        className="table-container"
        style={{
          overflow: 'scroll',
          minHeight: '800px',
          maxHeight: '400px',
          paddingBottom: '70px',
        }}
      >
        {selectedPathway.identifier && (
          <section ref={diagramTitleRef} className="pathway-diagram-title">
            <h1>
              {selectedPathway.name} ({selectedPathway.identifier})
            </h1>
            <div className="row justify-content-center">
              <button className="btn btn-light btn-outline-secondary btn-sm" onClick={scrollToPathwaysList}>
                view more pathways
              </button>
            </div>
          </section>
        )}

        <section className="pathway-diagram-buttons">
          <button onClick={() => highlightPathway(geneSymbol)}>Show Highlights</button>
        </section>
        <section className="flex-container">
          <div id="diagramHolder" />
        </section>

        <section ref={pathwaysListRef} className="flex-container">
          <PathwayDiagramList
            diagramTitleRef={diagramTitleRef}
            geneSymbol={geneSymbol}
            pathways={availablePathways}
            selectedPathway={selectedPathway}
            onSelectPathway={onChangePathway}
          />
        </section>
      </div>
    </Fragment>
  );
}
