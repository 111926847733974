import React, { useState, useCallback, useEffect } from 'react';
import fetchEndpoint from '../../../Services/fetchEndpoint';
import { sortChartData, getYAxisValues, setLineXMargins } from '../Charts/Line/LineHelpers';

import { userAppContext, searchContext } from '../../../Context/Context';
import buildGraphEndpoint from '../../Utils/buildGraphEndpoint';

function useLineChart({
  chartInfo,
  initialChartData,
  keySearch,
  source,
  baseUrl,
  parent,
  view,
  getLineData,
  width,
  uniqueIdentViewsVariables,
}) {
  const [chartData, setChartData] = useState(initialChartData);
  const [yAxisValue, setYAxisValues] = useState({ max: 1, min: 0 });
  const [isPayloadEmpty, setIsPayloadEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [xMargin, setXMargin] = useState(null);

  const { authorization, setIsAuthenticated, setGroups, setAuthError, setAuthorization } =
    userAppContext() || {};

  const { tab, searchItems } = searchContext() || { tab: null, searchItems: [] };

  const setLineChartData = useCallback(() => {
    setIsLoading(true);

    const orderByParam =
      chartInfo.variable === 'numPMIDsupportingAssociation'
        ? `${chartInfo.variable}_${source}`
        : chartInfo.variable;

    const pageNum = 0;
    const pageSize = 20;
    const order = 'desc';
    const url = buildGraphEndpoint(view, source, tab, searchItems, pageNum, pageSize, orderByParam, order);
    return fetchEndpoint(
      url,
      authorization,
      setIsAuthenticated,
      setGroups,
      setAuthError,
      setAuthorization,
    ).then(responseData => {
      if (responseData === undefined) {
        return;
      }

      const data = responseData.payload;

      if (data?.length === 0) {
        setIsPayloadEmpty(true);
        setIsLoading(false);
        return;
      } else {
        setIsPayloadEmpty(false);
      }

      const mappingVariables = uniqueIdentViewsVariables[parent].mappingIdents[view];
      const model = uniqueIdentViewsVariables[parent].model;

      const transformedLineData = getLineData(data, mappingVariables, model, chartInfo.variable);

      if (transformedLineData.length === 0) {
        setChartData([]);
        setIsLoading(false);
        return;
      }

      const newYAxisValues = getYAxisValues(chartInfo.variable, data);
      setYAxisValues(newYAxisValues);

      const totalResults = transformedLineData[0].data.length;
      setXMargin(() => setLineXMargins(width, totalResults));

      sortChartData(transformedLineData);

      setChartData(transformedLineData);
      setIsLoading(false);
    });
  }, [source, tab, baseUrl, keySearch, view, parent, chartInfo]);

  useEffect(() => {
    setLineChartData();
  }, [setLineChartData]);

  return {
    chartData,
    yAxisValue,
    isPayloadEmpty,
    isLoading,
    xMargin,
    setXMargin,
  };
}

export default useLineChart;
