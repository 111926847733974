import { useState } from 'react';
import Axios from 'axios';
import { API_URL } from '../Components/Constants';
import { validateInput } from '../Components/AdvancedSearch/AdvancedSearchHelpers';

const useFetchSuggestions = (searchType, selectedFilter, authorization, isAuthenticated) => {
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [foundItems, setFoundItems] = useState([]);
  const [notFoundItems, setNotFoundItems] = useState([]);

  const baseEndpoint = `${API_URL}api/advancedsearch`;

  const fetchSuggestions = async inputs => {
    if (!selectedFilter) return;

    const validInputs = [];
    const invalidInputs = [];

    inputs.forEach(input => {
      if (!validateInput(input, searchType, selectedFilter)) {
        invalidInputs.push({ query: input, validationError: 'Invalid input format' });
      } else {
        validInputs.push(input);
      }
    });

    if (invalidInputs.length > 0) {
      setSuggestionsData(
        invalidInputs.map(item => ({
          query: item.query,
          found: false,
          error: item.validationError,
          result: [],
        })),
      );
    }

    if (validInputs.length === 0) {
      setError('No valid inputs provided.');
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await Axios.get(baseEndpoint, {
        params: {
          pattern: validInputs.join(','),
          filter: selectedFilter,
          type: searchType,
          ...(isAuthenticated ? {} : { skey: 'unauth' }),
        },
        headers: {
          Authorization: authorization,
        },
      });

      const payload = response.data.payload || {};
      const allSuggestions = validInputs.map(input => {
        let isFound = false;
        let resultData = [];

        switch (searchType) {
          case 'DISEASES': {
            const diseases = payload.diseases || [];
            switch (selectedFilter) {
              case 'diseaseUMLSCUI':
                resultData = diseases.filter(disease => disease.diseaseUMLSCUI === input);
                break;
              case 'MSH':
                resultData = diseases.filter(disease => disease.msh.includes(input));
                break;
              case 'OMIM':
                resultData = diseases.filter(disease => disease.omim.includes(input));
                break;
              case 'NCI':
                resultData = diseases.filter(disease => disease.nci.includes(input));
                break;
              case 'ORDO':
                resultData = diseases.filter(disease => disease.ordo.includes(input));
                break;
              case 'HPO':
                resultData = diseases.filter(disease => disease.hpo.includes(input));
                break;
              case 'MONDO':
                resultData = diseases.filter(disease => disease.mondo.includes(input));
                break;
              default:
                resultData = [];
                console.error(`Unknown filter type: ${selectedFilter}`);
                break;
            }
            isFound = resultData.length > 0;
            break;
          }

          case 'VARIANTS': {
            const variants = payload.variants || [];
            if (selectedFilter === 'strID') {
              resultData = variants.filter(variant => variant.strID === input);
            } else if (selectedFilter === 'geneSymbol') {
              resultData = variants.filter(variant => variant.geneSymbol === input);
            }
            isFound = resultData.length > 0;
            break;
          }
          case 'GENES': {
            const genes = payload.genes || [];
            switch (selectedFilter) {
              case 'symbol':
                resultData = genes.filter(gene => gene.symbol === input);
                break;
              case 'geneID':
                resultData = genes.filter(gene => gene.geneID === parseInt(input, 10));
                break;
              case 'ensemblID':
                resultData = genes.filter(gene => gene.ensembl_ids.includes(input));
                break;
              case 'uniprotID':
                resultData = genes.filter(gene => gene.proteinStrIDs.includes(input));
                break;

              default:
                resultData = [];
            }
            isFound = resultData.length > 0;
            break;
          }
          case 'CHEMICALS': {
            const chemicals = payload.chemicals || [];
            if (selectedFilter === 'chemUMLSCUI') {
              resultData = chemicals.filter(chemical => chemical.chemUMLSCUI === input);
            } else if (selectedFilter === 'chemShowName') {
              resultData = chemicals.filter(
                chemical => chemical.chemShowName.toLowerCase() === input.toLowerCase(),
              );
            }
            isFound = resultData.length > 0;
            break;
          }
          default:
            break;
        }

        return {
          query: input,
          found: isFound,
          results: resultData,
        };
      });

      setSuggestionsData([...allSuggestions, ...invalidInputs]);
      setFoundItems(allSuggestions.filter(item => item.found).map(item => item.results[0]));
      setNotFoundItems(allSuggestions.filter(item => !item.found).map(item => item.query));
    } catch (err) {
      console.error('Error fetching suggestions:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { suggestionsData, loading, error, foundItems, notFoundItems, fetchSuggestions };
};

export default useFetchSuggestions;
