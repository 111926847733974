import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import SupportContent from '../Components/Support/SupportContent';
import Footer from '../Components/Home/Footer';
import $ from 'jquery';

export default function Support() {
  $('.popover').hide();
  ReactGA.send({ hitType: 'pageview', page: '/Support', title: 'Contact' });

  return (
    <React.Fragment>
      <CommonHeader />
      <SupportContent />
      <Footer />
    </React.Fragment>
  );
}
