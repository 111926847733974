import React from 'react';

const TextInput = ({ value, setValue }) => {
  return (
    <input
      type="text"
      autoComplete="off"
      aria-autocomplete="list"
      aria-controls="react-autowhatever-source"
      placeholder="Examples: Obesity,  LEPR,  rs121913279,  streptozocin..."
      value={value}
      onChange={e => setValue(e.target.value)}
    ></input>
  );
};

export default TextInput;
